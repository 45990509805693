html {
    background-color: #fafafa;
    color: #1f2937;
    font-family: 'Rosario';
    font-size: 21px;
    line-height: 32px;
}

body {
    margin: 0 auto;
    padding: 0;
    max-width: 72ch;
    width: 100%;
    padding: 36px 18px;
}

main {
    position: relative;
    min-height: 100vh;
}

.content {
    margin-top: 1.5rem;
    padding-bottom: 4rem;
}

.blog-date {
    color: #666666;
    padding-left: 8px;
    padding-right: 8px;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 1rem;
    height: 24px;
}

h1 {
    font-size: 2.75rem;
    font-weight: 700;
    line-height: 3rem;
    margin: 1rem 0;
}

h1.title {
    margin: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.5rem;
}

h2 {
    font-size: 2rem;
    line-height: 2rem;
    font-weight: 700;
}

a {
    font-size: 1.125rem;
    color: #be123c;
    font-weight: 500;
    text-decoration: none;
}

a:hover, a:hover:visited {
    color: #f43f5e;
    text-decoration: underline;
}

figure {
    margin: 0;
}

code {
    font-family: 'Ubuntu Mono';
    font-size: 0.95em;
}

figure.highlight {
    font-family: 'Ubuntu Mono';
    font-size: 0.95em;
    letter-spacing: -0.02em;
    line-height: 1em;
}

small {
    font-size: 0.8em;
}

.poem p {
    white-space: pre;
}

.back-link {
    margin-top: 1.5rem;
}

* {
    box-sizing: border-box;
}

blockquote {
    font-size: 1.25em;
    line-height: 1.2em;
    font-style: italic;
    color: #50627a;
    border-left: 5px solid #cccccc;
    padding-left: 1rem;
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
}

.highlighter-rouge {
    font-family: 'Ubuntu Mono';
    background-color: #312f31;
    color: #f9f5fb;
    font-size: 0.95em;
}
